import React, { useEffect, useState } from 'react';
import { NavBar } from './Nav';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';

import '../style.css';
import '../index.css';

function App() {
  return (
    <div>
      <header>
        <NavBar />
      </header>

      <main>
        <Switch>
          <Route exact path="/">
            <div></div>
          </Route>

          <Redirect to="/" />

        </Switch>
      </main>

      <footer>
        <p className="copyright-text">
          Copyright &copy; 2021 All Rights Reserved by Yakima Community Aid
        </p>
      </footer>
    </div>
  );
}

export default App;
